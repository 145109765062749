<template>
  <div class="checkbox-container">
    <svg :style="`width: ${size}; height: ${size}; min-width: ${size}; min-height: ${size}`" viewBox="0,0,24,24" class="checkbox" :class="{ readonly, disabled, checked: modelValue }" @click="toggle" v-bind="{ id: id }">
      <defs>
        <clipPath :id="`platformFrontendCheckboxOutline${checkboxID}`">
          <circle cx="12" cy="12" r="11"/>
        </clipPath>
      </defs>
      <circle cx="12" cy="12" r="11" class="outline" v-bind='{ "clip-path":"url(#platformFrontendCheckboxOutline" + checkboxID + ")" }' />
      <polyline points="6,12,10,16,17,8" class="check" />
    </svg>
    <span v-if="!!label" @click="toggle" class="checkbox-label" :class="{ readonly, disabled }">{{ label }}</span>
    <slot>
      <!-- Deze is hier zodat tooltips e.d. binnen de container gezet kunnen worden -->
    </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getNextCheckboxID } from "./CheckboxStore";

export default defineComponent({
  props: {
    modelValue: Boolean,
    label: String,
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: String,
    size: {
      type: String,
      default: '24px'
    }
  },

  data()
  {
    return {
      // Geef een uniek ID aan de outline, die moet namelijk via een ID maar als er checkboxes van meerdere
      // sizes op de page zijn dan geeft dit conflicten en renderen ze verkeerd ook al zijn alle viewboxen gelijk.
      // SVG rendering is weird.
      checkboxID: getNextCheckboxID()
    }
  },

  methods: {
    toggle() 
    {
      if (this.readonly || this.disabled)
        return;

      this.$emit("update:modelValue", !this.modelValue);
    },
  },
});
</script>

<style lang="scss">
@import "../scss/platformstyle-variables.scss";

.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox
{
  display: inline-block;
  
  .outline
  {
    fill: none; 
    stroke: #d6e0e6; 
    stroke-width: 4;
    transition: all 350ms cubic-bezier(0, 0.89, 0.44, 1);
  }
  
  .check
  {
    fill: none;
    stroke: white;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    transform-origin: center center;
    transform: scale(0);
    opacity: 0;
    transition: all 350ms cubic-bezier(0, 0.89, 0.44, 1);
  }

  &.checked
  {
    .outline
    {
      stroke: $platform-primary;
      stroke-width: 22; // 2x de radius, groter of kleiner en je krijgt een witte punt in het midden in Firefox
    }

    .check
    {
      transform: scale(1);
      opacity: 1;
    }
  }

  &.disabled
  {
    .outline
    {
      fill: #f0f0f0;
      stroke: #f0f0f0;
    }
  }

  &.checked.readonly
  {
    .outline
    {
      stroke: lightgray;
    }


    .background
    {
      fill: lightgray;
      stroke: none;
    }
  }
}


.checkbox-label
{
  margin-left: .75em;

  &.disabled
  {
    color: lightgray;
  }
}


.checkbox, .checkbox-label
{
  cursor: pointer;
  user-select: none;

  &.readonly
  {
    cursor: inherit;
  }
}
</style>
