import { createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "checkbox-container" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", _mergeProps({
      style: `width: ${_ctx.size}; height: ${_ctx.size}; min-width: ${_ctx.size}; min-height: ${_ctx.size}`,
      viewBox: "0,0,24,24",
      class: ["checkbox", { readonly: _ctx.readonly, disabled: _ctx.disabled, checked: _ctx.modelValue }],
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, { id: _ctx.id }), [
      _createElementVNode("defs", null, [
        _createElementVNode("clipPath", {
          id: `platformFrontendCheckboxOutline${_ctx.checkboxID}`
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("circle", {
            cx: "12",
            cy: "12",
            r: "11"
          }, null, -1)
        ]), 8, _hoisted_2)
      ]),
      _createElementVNode("circle", _mergeProps({
        cx: "12",
        cy: "12",
        r: "11",
        class: "outline"
      }, { "clip-path":"url(#platformFrontendCheckboxOutline" + _ctx.checkboxID + ")" }), null, 16),
      _cache[3] || (_cache[3] = _createElementVNode("polyline", {
        points: "6,12,10,16,17,8",
        class: "check"
      }, null, -1))
    ], 16)),
    (!!_ctx.label)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args))),
          class: _normalizeClass(["checkbox-label", { readonly: _ctx.readonly, disabled: _ctx.disabled }])
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}