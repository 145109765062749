<template>
  <div id="passwordRequirements">
    <span id="title">{{ t('labels.title') }}</span>
    <ul class="requirementsList">
      <li v-if="containsUserData" class="error">
        {{ t('errors.cantContainUserData') }}
      </li>
      <li v-if="wachtwoordRequirements.minimumLength" :class="{passed: passedLengthRequirement}">
        {{ t('labels.minLength', {aantal: wachtwoordRequirements.minimumLength}) }}
      </li>
      <li v-if="wachtwoordRequirements.includeCapital" :class="{passed: passedCapitalRequirement}">
        {{ t('labels.includeCapital') }}
      </li>
      <li v-if="wachtwoordRequirements.includeNumber" :class="{passed: passedNumberRequirement}">
        {{ t('labels.includeNumber') }}
      </li>
      <li v-if="wachtwoordRequirements.includeSymbol" :class="{passed: passedSymbolRequirement}">
        {{ t('labels.includeSymbol') }}
      </li>
      <li v-if="currentPasswordMatchingNew" class="error">
        {{ t('errors.passwordsTheSame') }}
      </li>
      <li v-if="!currentPasswordMatchingNew && !newPasswordConfirmed" class="error">
        {{ t('errors.passwordsMustMatch') }}
      </li>
    </ul>
  </div>
</template>

<i18n lang="yaml" src="./locale/PasswordRequirementsChecker.yaml"/>

<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from 'vue-i18n';

export interface WachtwoordRequirements {
  minimumLength: number,
  includeCapital: boolean,
  includeNumber: boolean,
  includeSymbol: boolean,
  forbiddenUserInfo: string[],
}

export interface VerificationResult {
  IsValid: boolean,
  Message: string
}

const props = defineProps({
  wachtwoordRequirements: {
    type: Object as () => WachtwoordRequirements,
    required: true
  },
  currentPassword: String,
  newPassword: {
    type: String,
    required: true,
  },
  newPasswordConfirm: {
    type: String,
    required: true,
  },
  checkMatchingCurrentNew: {
    type: Boolean,
    default: false
  },
  checkNewPaswswordConfirmed: {
    type: Boolean,
    default: true
  }
});

defineExpose({
  verify,
})

const { t } = useI18n();
const passedLengthRequirement = computed<boolean>(() => props.newPassword.length >= props.wachtwoordRequirements.minimumLength);
const passedCapitalRequirement = computed<boolean>(() => /[A-Z]/.test(props.newPassword));
const passedNumberRequirement = computed<boolean>(() => /[0-9]/.test(props.newPassword));
const passedSymbolRequirement = computed<boolean>(() => /[-!"#$%&'()*+,./:;<=>?@\[\]^_`{|}~]/.test(props.newPassword));
const containsUserData = computed<boolean>(() => {
  const loweredPassword = props.newPassword.toLowerCase();
  const containsUserdata = props.wachtwoordRequirements.forbiddenUserInfo.some(fv => loweredPassword.includes(fv));
  return containsUserdata;
});
const currentPasswordMatchingNew = computed<boolean>(() => {
  return props.checkMatchingCurrentNew && props.currentPassword!.length > 0 && props.newPassword.length > 0 && props.currentPassword === props.newPassword
})
const newPasswordConfirmed = computed<boolean>(() => !props.checkNewPaswswordConfirmed || props.newPassword === props.newPasswordConfirm)


function verify(): VerificationResult
{
  let messages: string[] = [];
  let verificationResult = { IsValid: true, Message: '' };

  if (props.wachtwoordRequirements.minimumLength && !passedLengthRequirement.value)
    messages.push(t('errors.minLength', { aantal: props.wachtwoordRequirements.minimumLength }));

  if (props.wachtwoordRequirements.includeCapital && !passedCapitalRequirement.value)
    messages.push(t('errors.includeCapital'));

  if (props.wachtwoordRequirements.includeNumber && !passedNumberRequirement.value)
    messages.push(t('errors.includeNumber'));

  if (props.wachtwoordRequirements.includeSymbol && !passedSymbolRequirement.value)
    messages.push(t('errors.includeSymbol'));

  if (containsUserData.value)
    messages.push(t('errors.cantContainUserData'));

  if (currentPasswordMatchingNew.value)
    messages.push(t('errors.passwordsTheSame'))

  if (!newPasswordConfirmed.value)
    messages.push(t('errors.passwordsMustMatch'))

    
  if (messages.length) {
    verificationResult.IsValid = false;
    verificationResult.Message = '<ul>'
    messages.forEach(m => {
      verificationResult.Message += '<li>' + m + '</li>';
    })
    verificationResult.Message += '</ul>'
  }

  return verificationResult;
}

</script>

<style lang="scss" scoped>
div#passwordRequirements {
  text-align: left;

  span#title{
    padding-left: 10px;
    color: gray;
  }

  .requirementsList {
    margin-bottom: 0;

    li {
      color: gray;
    }

    li.passed  {
      font-weight: bold;
      color: rgb(60, 167, 69);
    }
    li.error {
      color: darkred;
      font-weight: bold;
    }
  }
}
</style>