<template>
    <svg width="31" height="31" viewBox="0 0 31 31" class="loader" :class="style">
        <path class="section section1" d="M15.516,5.107c2.513,0,4.807,0.988,6.553,2.617l3.513-3.512c-2.645-2.533-6.17-4.104-10.065-4.104  c-0.005,0-0.01,0.001-0.016,0.001v5C15.505,5.108,15.51,5.107,15.516,5.107z" />
        <path class="section section2" d="M25.328,14.5h4.999c-0.227-3.683-1.717-7.033-4.045-9.575l-3.518,3.518  C24.185,10.058,25.124,12.152,25.328,14.5z" />
        <path class="section section3" d="M25.374,15.5c-0.007,2.479-0.824,4.726-2.179,6.488l3.528,3.528c2.265-2.7,3.644-6.205,3.65-10.017H25.374z" />
        <path class="section section4" d="M15.516,25.84c-0.005,0-0.01-0.001-0.016-0.001v5c0.005,0,0.01,0.001,0.016,0.001  c4.133,0,7.86-1.757,10.549-4.568l-3.518-3.518C20.754,24.666,18.263,25.84,15.516,25.84z" />
        <path class="section section5" d="M8.47,22.737l-3.517,3.518c2.466,2.587,5.815,4.269,9.547,4.531v-5.001  C12.149,25.534,10.04,24.419,8.47,22.737z" />
        <path class="section section6" d="M5.658,15.5h-5c0.006,3.804,1.379,7.303,3.635,10l3.53-3.529C6.477,20.21,5.664,17.971,5.658,15.5z" />
        <path class="section section7" d="M8.252,8.459L4.734,4.941c-2.32,2.54-3.804,5.884-4.03,9.559h4.999C5.906,12.16,6.84,10.072,8.252,8.459z" />
        <path class="section section8" d="M14.5,5.162V0.161c-3.499,0.247-6.652,1.748-9.065,4.066L8.947,7.74C10.457,6.324,12.378,5.388,14.5,5.162z" />
    </svg>
</template>


<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{
    style?: string,
    sectionColor?: string,
    activeSectionColor?: string
}>();


// Voor backwards compatibility met de ingebouwde 'white' style, vandaar dat we 
// geen default values voor de props gebruiken
const defaultActiveSectionColor = computed(() => props.style === 'white' ? 'white' : '$activeSectionColor');

const sectionColor = computed(() => props.sectionColor || '#9a9a9a');
const activeSectionColor = computed(() => props.activeSectionColor || defaultActiveSectionColor.value);
</script>

<style lang="scss">
$sectionColor: v-bind(sectionColor);
$activeSectionColor: v-bind(activeSectionColor);

@keyframes loader-section-keyframes {
    0% { fill: $activeSectionColor }
    50% { fill: $sectionColor; }
}

$sectionDelays: (
    "1": 0,
    "2": 0.125s,
    "3": 0.25s,
    "4": 0.375s,
    "5": 0.5s,
    "6": 0.625s,
    "7": 0.75s,
    "8": 0.875s
);

.loader
{
    .section
    {
        fill: $sectionColor;

        @each $i, $delay in $sectionDelays 
        {
            &.section#{$i} 
            {
                animation-name: loader-section-keyframes;
                animation-duration: 1s;
                animation-delay: #{$delay};
                animation-iteration-count: infinite;
            }
        }
    }
}
</style>