<template>
    <input class="form-control" type="text" v-model="mfaCode" placeholder="Code" @keydown="mfaCodeInput" ref="verifyCodeRef" autocomplete="off" :maxlength="6" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const mfaCode = computed({
    get () {
        return props.modelValue
    },
    set (value) {
        emit('update:modelValue', value)
    }
})

async function mfaCodeInput(event: KeyboardEvent) {
    if (/^[a-zA-Z]$/i.test(event.key) && !event.ctrlKey) {
        event.preventDefault();
        return;
    }

    if (/^[0-9]$/i.test(event.key) && mfaCode.value.length >= 6) {
        event.preventDefault();
        return;
    }
}

</script>